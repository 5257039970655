<template>
    <v-card>
      <v-card-title>
        Gateways
        <!-- <v-icon class="ml-2" :color="siteColor" @click="addGateway">mdi-store-plus-outline</v-icon> -->
        <div class="flex-grow-1"></div>
        <v-text-field v-model="search" clearable label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        v-if="gateways.length"
        :headers="headers"
        :items="gateways"
        :items-per-page="30"
        item-key="id"
        class="elevation-1"
        :search="search"
        sort-by="approved"
      >
        <template v-slot:body="{items}">
          <tbody>
            <template v-for="item in items">
              <tr :key="item.devEui">
                <td>{{ item.devEui }}</td>
                <td>{{ item.connected ? 'Connected' : 'Disconnected' }}</td>
                <td>{{ lastUpdated(item.lastCheckIn) }}</td>
                <td>{{ checkOwner(item) }}</td>
                <td v-if="item.sensors.items.length">
                        <v-chip v-for="sensor in item.sensors.items"
                        :color="checkSignalStrengthColor(sensor.rssi)"
                        label
                        dark
                        class="mr-1 my-1"
                        @click="routeTo(sensor.uuid)"
                        v-if="sensor.rssi"
                        >{{ sensor.name }}</v-chip>
                </td>
                <td v-else></td>
                <!-- <td style="display: flex; justify-content: end;"><v-icon color="red" @click="confirmDelete(item.id)">mdi-store-remove-outline</v-icon></td> -->
              </tr>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  export default {
    name: "gateways",
    data() {
      return {
        search: "",
        headers: [
          { text: "DevEui", value: "devEui" },
          { text: "Status", value: "connected"},
          { text: "Last CheckIn", value: "lastCheckIn" },
          { text: "Owner", value: "owner" },
          { text: "Sensors / Signal Strength", value: "" }
        ]
      };
    },
    async created() {
      this.$store.dispatch("user/getAllUsers");
      this.$store.dispatch("location/allLocations");
      if (this.$route.params.gw) this.search = this.$route.params.gw
      await this.$store.dispatch("sensor/getAllGateways");
    },
    computed: {
      ...mapState("user", ["user", "users"]),
      ...mapState("location", ["locations"]),
      ...mapState("sensor", ["gateways"]),
      ...mapGetters("user", [
        "isSuper",
      ]),
    },
    methods: {
        sortRssi(sensors) {
            if (!sensors.length) return []
            return sensors.sort((a,b) => a.rssi > b.rrsi ? 1 : b.rssi > a.rssi ? -1 : 0)
        },
      confirmDelete(id) {
        this.$swal({
        title: 'Remove Store',
        text: "Are you sure you want to remove this store?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#4baf4f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        'cancelButtonText': 'No',
        'iconColor': '#d33'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteStore(id)
          }
        });
      },
      async deleteStore(id) {
        await this.$store.dispatch("store/removeStore", id);
      },
      checkSensors(items) {
        
      },
      lastUpdated(date) {
        if (!date) return 'NA'
        let fullDate = new Date(date).toLocaleDateString();
        let todayDate = new Date().toLocaleDateString();
        if (fullDate === todayDate) {
            return `${new Date(date).toLocaleTimeString()}`
        }
        else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
      },
      checkOwner(gw) {
        if (!gw.owner) {
            if (gw.sensors.items.length) {
                let locId = gw.sensors.items[0].sensorLocationId
                let found = this.locations.find(item => item.id === locId)
                if (found) return found.name
                else {
                    return ''
                }
            }
            else return ''
        }
        else {
            let found = this.users.find(item => item.id === gw.gatewayOwnerId)
            if (found) return found.email
            else return ''
        }
      },
      routeTo(route) {
        this.$router.push(`/device/${route}`)
      },
      checkSignalStrengthColor(rssi) {
        let strength = Number(rssi)
        return strength > -71 ? 'green' : strength <= -70 && strength >= -80 ? '#76dc00' : strength <= -81 && strength >= -90 ? '#d8d300' : 'red'
      },
      async addGateway() {
        this.$swal({
        title: 'Add Store',
        input: "text",
        inputLabel: "Store Name",
        showCancelButton: true,
        confirmButtonColor: '#4baf4f',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Add',
        'iconColor': '#d33',
        inputValue: "",
        inputValidator: (value) => {
          if (!value) {
            return "You need to enter a store name!";
          }
        }
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.$store.dispatch("store/addStore", result.value);
          }
        });
        
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  tr {
    cursor: pointer;
  }
  table {
    td {
      .v-text-field {
        border-color: #ffffff1f;
        padding-top: 0px;
        margin-top: 0px;
        font-size: 14px;
      }
    }
  }
  </style>